import React from 'react'
import PropTypes from 'prop-types'
import { useCubeQuery } from '@cubejs-client/react'
import { v4 as uuidv4 } from 'uuid'
import Utils from 'utils'
import {
  Spin, Row, Col, Statistic, Table,
} from 'antd'
import {
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line,
} from 'recharts'
import _ from 'lodash'
import styled from 'styled-components'
import colors from './colors'

import './recharts-theme.less'
import BenchmarkTable from './custom/BenchmarkTable'
import ExcludedReasonsTable from './custom/ExcludedReasonsTable'
import SourcingInsightsTable from './custom/SourcingInsightsTable'
import UsersActivitiesTable from './custom/UsersActivitiesTable'
import AffordableIndexLine from './custom/AffordableIndexLine'
import MarketAffordabilityLine from './custom/MarketAffordabilityLine'
import CartesianChart from './CartesianChart'
import MBTCasesBar from './custom/MBTCasesBar'
import TopXBarChart from './custom/TopXBarChart'
import MyDeviation from './custom/MyDeviation'

const TypeToChartComponent = {
  line: ({ resultSet, height, vizState }) => (
    <CartesianChart ChartComponent={LineChart} data={resultSet.chartPivot()} height={height} vizState={vizState}>
      {resultSet.seriesNames().map((series, i) => (
        <Line
          dataKey={series.key}
          key={series.key}
          name={series.title}
          stroke={colors[i % (colors.length - 1)]}
        />
      ))}
    </CartesianChart>
  ),
  mbtCasesBar: ({ resultSet, height, vizState }) => (
    <MBTCasesBar
      height={height}
      resultSet={resultSet}
      vizState={vizState}
    />
  ),
  mbtCasesTable: ({ resultSet, height, vizState }) => (
    <MBTCasesBar
      height={height}
      resultSet={resultSet}
      vizState={vizState}
    />
  ),
  myDeviationBar: ({ resultSet, height, vizState }) => (
    <MyDeviation
      height={height}
      resultSet={resultSet}
      vizState={vizState}
    />
  ),
  myDeviationTable: ({ resultSet, height, vizState }) => (
    <MyDeviation
      height={height}
      resultSet={resultSet}
      vizState={vizState}
    />
  ),
  bar: ({ resultSet, height, vizState }) => (
    <CartesianChart ChartComponent={BarChart} data={resultSet.chartPivot()} height={height} vizState={vizState}>
      {resultSet.seriesNames().map((series, i) => (
        <Bar
          dataKey={series.key}
          fill={colors[i % (colors.length - 1)]}
          key={series.key}
          name={series.title}
          stackId="a"
        />
      ))}
    </CartesianChart>
  ),
  topXBarChart: ({ resultSet, height, vizState }) => (
    <TopXBarChart height={height} resultSet={resultSet} vizState={vizState} />
  ),
  topXTable: ({ resultSet, height, vizState }) => (
    <TopXBarChart height={height} resultSet={resultSet} vizState={vizState} />
  ),
  area: ({ resultSet, height, vizState }) => (
    <CartesianChart ChartComponent={AreaChart} data={resultSet.chartPivot()} height={height} vizState={vizState}>
      {resultSet.seriesNames().map((series, i) => (
        <Area
          dataKey={series.key}
          fill={colors[i]}
          key={series.key}
          name={series.title}
          stackId="a"
          stroke={colors[i]}
        />
      ))}
    </CartesianChart>
  ),
  pie: ({ resultSet, height, vizState }) => (
    <ResponsiveContainer height={height} vizState={vizState} width="100%">
      <PieChart>
        <Pie
          data={resultSet.chartPivot()}
          dataKey={resultSet.seriesNames()[0].key}
          fill="#8884d8"
          isAnimationActive={false}
          nameKey="x"
        >
          {resultSet.chartPivot().map((e, index) => (
            <Cell fill={colors[index % colors.length]} key={index} />
          ))}
        </Pie>
        <Legend />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  ),
  table: ({ resultSet, vizState }) => {
    const sort = (a, b, { key }) => {
      if (key.includes('updatedAt')) return a[key].localeCompare(b[key])

      return a[key] - b[key]
    }

    const alteredData = resultSet.tablePivot().map((item) => _.reduce(item, (res, value, key) => {
      if (key.includes('updatedAt')) return { ...res, [key]: value.split('T')[0], key: uuidv4() }

      return { ...res, [key]: _.round(value, 2), key: uuidv4() }
    }, {}))

    Utils.Report.setData(vizState.id, alteredData, resultSet.tableColumns())

    return (
      <Table
        columns={resultSet.tableColumns().map((c) => ({
          ...c, dataIndex: c.key, title: c.shortTitle, sorter: (a, b) => sort(a, b, c),
        }))}
        dataSource={alteredData}
        pagination={false}
        style={{ height: '300px', overflowY: 'auto' }}
      />
    )
  },
  benchmarkTable: ({ resultSet, vizState, height }) => (
    <BenchmarkTable
      resultSet={resultSet}
      vizState={vizState}
      height={height}
    />
  ),
  excludedReasonsTable: ({
    resultSet, vizState, height, cubeQueryOnPageRenderDisabled,
  }) => (
    <ExcludedReasonsTable
      resultSet={resultSet}
      vizState={vizState}
      height={height}
      cubeQueryOnPageRenderDisabled={cubeQueryOnPageRenderDisabled}
    />
  ),
  sourcingInsightsTable: ({ resultSet, vizState, height }) => (
    <SourcingInsightsTable
      resultSet={resultSet}
      vizState={vizState}
      height={height}
    />
  ),
  usersActivitiesTable: ({ vizState, height }) => (
    <UsersActivitiesTable
      vizState={vizState}
      height={height}
    />
  ),
  affordableIndexLine: ({ resultSet, vizState, height }) => (
    <AffordableIndexLine
      height={height}
      resultSet={resultSet}
      vizState={vizState}
    />
  ),
  affordableIndexTable: ({ resultSet, vizState, height }) => (
    <AffordableIndexLine
      height={height}
      resultSet={resultSet}
      vizState={vizState}
    />
  ),
  marketAffordabilityLine: ({ resultSet, vizState, height }) => (
    <MarketAffordabilityLine
      height={height}
      resultSet={resultSet}
      vizState={vizState}
    />
  ),
  marketAffordabilityTable: ({ resultSet, vizState, height }) => (
    <MarketAffordabilityLine
      height={height}
      resultSet={resultSet}
      vizState={vizState}
    />
  ),
  marketAffordabilityBar: ({ resultSet, vizState, height }) => (
    <MarketAffordabilityLine
      height={height}
      resultSet={resultSet}
      vizState={vizState}
    />
  ),
  number: ({ resultSet }) => (
    <Row
      align="middle"
      justify="center"
      style={{
        height: '100%',
      }}
      type="flex"
    >
      <Col>
        {resultSet.seriesNames().map((s) => (
          <Statistic key={s.key} value={resultSet.totalRow()[s.key]} />
        ))}
      </Col>
    </Row>
  ),
}
const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }))

const SpinContainer = styled.div`
  text-align: center;
  padding: 30px 50px;
  margin-top: 30px;
`
const Spinner = () => (
  <SpinContainer>
    <Spin size="large" />
  </SpinContainer>
)

const REDIRECT_ERRORS = [
  'Error: Token is expired',
  'Error: Invalid token',
  'Error: Role is not lender',
]

const renderChart = (Component) => ({
  resultSet, error, height, vizState, cubeQueryOnPageRenderDisabled,
}) => {
  if (error && REDIRECT_ERRORS.includes(error.toString())) {
    // eslint-disable-next-line no-console
    console.error('error from CubeJS')
    location.href = `${location.origin.replace('lender-portal.', '')}/dashboard/home`
    return null
  }
  return (
    resultSet && (
      <Component
        height={height}
        resultSet={resultSet}
        vizState={vizState}
        cubeQueryOnPageRenderDisabled={cubeQueryOnPageRenderDisabled}
      />
    )
  )
    || (error && error.toString()) || <Spinner />
}

const ChartRenderer = ({ vizState, chartHeight, identifier }) => {
  const { query, chartType } = vizState
  let cubeQueryOnPageRenderDisabled = false
  if (identifier === 'competitorWillNotLend') {
    const competitorWillNotLendLenderIdQuery = query.filters.find((filter) => filter.dimension === 'ExcludedReasons.lender_id')
    cubeQueryOnPageRenderDisabled = competitorWillNotLendLenderIdQuery?.values?.length === 0
  }
  const component = TypeToMemoChartComponent[chartType]
  const renderProps = query && !cubeQueryOnPageRenderDisabled
    ? useCubeQuery(query)
    : { isLoading: false, resultSet: [], cubeQueryOnPageRenderDisabled }
  return component && renderChart(component)({ height: chartHeight, ...renderProps, vizState })
}

ChartRenderer.propTypes = {
  cubejsApi: PropTypes.object,
  vizState: PropTypes.object,
}
ChartRenderer.defaultProps = {
  chartHeight: 300,
  cubejsApi: null,
  vizState: {},
}
export default ChartRenderer
