import { Form, Select, Tag } from 'antd'
import React, { useState } from 'react'
import { getIn } from 'utils/immutable'
import fetchOptions from 'core/fetchOptions'

const MultiLenders = ({ updateItem, item, currentLenderId }) => {
  const [options, setOptions] = useState([])
  const lenders = getIn(item, ['vizState', 'lenders']) || []
  const [selectedLenders, setSelectedLenders] = useState(lenders)

  const handleSearch = (value) => {
    if (value) {
      fetchOptions(
        '/lender_portal/lenders/search',
        { q: value },
        (data) => setOptions(data),
      )
    } else {
      setOptions([])
    }
  }

  const tagRender = ({ label, closable, onClose }) => {
    const onPreventMouseDown = (event) => {
      event.preventDefault()
      event.stopPropagation()
    }

    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}>
        {label[1]}
      </Tag>
    )
  }

  const handleLenderChange = (value) => {
    setSelectedLenders(value)
    const lenderIds = value.map((lender) => lender?.[0]?.toString()) || []
    const newQuery = item.vizState.query.filters.map((filter) => {
      if (filter.dimension === 'CaseResults.lender_id') {
        return { ...filter, values: [...lenderIds, `${currentLenderId}`] }
      }
      if (filter.dimension === 'ExcludedReasons.lender_id') {
        return { ...filter, values: lenderIds }
      }
      return filter
    })
    const newItem = {
      ...item,
      vizState: {
        ...item.vizState,
        lenders: value,
        query: {
          ...item.vizState.query,
          filters: newQuery,
        },
      },
    }
    updateItem(item.id, newItem)
  }

  return (
    <div>
      <Form.Item label="Lender">
        <Select
          mode="multiple"
          showSearch
          value={selectedLenders}
          defaultValue={[]}
          placeholder="Search and Select Lender"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          tagRender={tagRender}
          onSearch={handleSearch}
          onChange={handleLenderChange}
          notFoundContent={<div>Not Found</div>}
          options={options.map((lender) => ({ value: [lender.id, lender.name], label: lender.name }))}
        />
      </Form.Item>
    </div>
  )
}

export default MultiLenders
