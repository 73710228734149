import React from 'react'
import { Form, Select } from 'antd'

const { Option } = Select

const FilterByTime = ({ defaultFilterByTime, setFilterByTime }) => {
  const handleChange = (value) => {
    setFilterByTime(value)
  }

  return (
    <Form.Item label="Filter by Time">
      <Select
        defaultValue={defaultFilterByTime}
        onChange={handleChange}
        placeholder="Select time"
      >
        <Option value="">All time</Option>
        <Option value="This week">This week</Option>
        <Option value="This month">This month</Option>
        <Option value="This quarter">This quarter</Option>
        <Option value="This year">This year</Option>
        <Option value="Last week">Last week</Option>
        <Option value="Last month">Last month</Option>
        <Option value="Last quarter">Last quarter</Option>
        <Option value="Last year">Last year</Option>
      </Select>
    </Form.Item>
  )
}

export default FilterByTime
