import Reruns from './Reruns'
import TopPosition from './TopPosition'
import ChartType from './ChartType'
import Product from './Product'
import LendersProducts from './LendersProducts'
import ProductType from './ProductType'
import ScenarioOrderStrategy from './ScenarioOrderStrategy'
import DataFormat from './DataFormat'
import Measure from './Measure'
import Dimension from './Dimension'
import GroupingByTime from './GroupingByTime'
import FilterByTime from './FilterByTime'
import CustomFilters from './CustomFilters'
import LenderGroups from './LenderGroups'
import Lenders from './Lenders'
import MultiLenders from './MultiLenders'

export default {
  reruns: Reruns,
  top_position: TopPosition,
  chart_type: ChartType,
  data_format: DataFormat,
  measure: Measure,
  dimension: Dimension,
  grouping_by_time: GroupingByTime,
  filter_by_time: FilterByTime,
  product_type: ProductType,
  scenario_order_strategy: ScenarioOrderStrategy,
  product: Product,
  lenders_products: LendersProducts,
  custom_filters: CustomFilters,
  lender_groups: LenderGroups,
  lenders: Lenders,
  multi_lenders: MultiLenders,
}
