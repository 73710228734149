import React, { useEffect } from 'react'
import LocalStorage from 'utils/LocalStorage'
import HasAccess from 'core/HasAccess'
import VERSION from 'VERSION'
import { connect, useDispatch, useSelector } from 'react-redux'
import { updateCharts, updateStoragePath } from 'core/charts'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import ChartRenderer from '../components/ChartRenderer'
import DashboardItem from '../components/DashboardItem'
import charts from './charts'
import CardHeader from '../components/CardHeader'

const DashboardLayout = styled.div`
  margin: 16px 28px 50px 28px;
`

const SectionWrapper = styled.div`
  margin-bottom: 16px;
`

const StyledCol = styled(Col)`
  margin-bottom: 16px;
`

const connector = connect(({ user: { permissions } }) => ({ permissions }), {})

const CommonPage = ({
  name, currentLenderId, permissions,
}) => {
  const defaultData = charts[name]({ currentLenderId })
  const storagePath = ['lenderPortal', 'charts', name, currentLenderId, VERSION]
  const dispatch = useDispatch()
  const data = useSelector(({ charts: { data } }) => data)
  const userData = useSelector(({ user }) => user)

  const setData = (data) => {
    dispatch(updateCharts(data, storagePath))
  }

  useEffect(() => {
    if (!LocalStorage.getIn(storagePath)) {
      setData(charts[name]({ currentLenderId }))
    } else {
      setData(LocalStorage.getIn(storagePath) || defaultData)
      // setData(defaultData)
    }
  }, [currentLenderId])

  useEffect(() => {
    dispatch(updateStoragePath(storagePath))
  }, [currentLenderId, name])

  const filteredData = data.filter((i) => !i.newFeature || HasAccess.run(permissions, 'lpNewFeature'))

  const renderDashboardItems = () => {
    const marketSegmentItems = filteredData.filter((i) => i.includedSegment === 'marketData')
    const benchmarkingSegmentItems = filteredData.filter((i) => i.includedSegment === 'benchmarking')
    const criteriaSegmentItems = filteredData.filter((i) => i.includedSegment === 'criteria')
    const affordabilitySegmentItems = filteredData.filter((i) => i.includedSegment === 'affordability')
    const sourcingSegmentItems = filteredData.filter((i) => i.includedSegment === 'sourcing')

    const otherItems = filteredData.filter((i) => i.includedSegment === 'other')

    const itemsWithoutSegment = filteredData.filter((i) => !i.includedSegment)

    const calculateSpanBasedOnLength = (items, currentItemIndex) => {
      const isLastItem = currentItemIndex === items?.length - 1
      if (items?.length % 2 === 0) {
        return 12
      }
      if (items?.length % 2 === 1 && isLastItem) {
        return 24
      }
      return 12
    }

    return (
      <div>
        {marketSegmentItems?.length ? (
          <SectionWrapper>
            <CardHeader title="Market Data" />
            <Row gutter={16}>
              {marketSegmentItems.map((item, index) => (
                <StyledCol key={item.id} span={calculateSpanBasedOnLength(marketSegmentItems, index)}>
                  <DashboardItem
                    data={data}
                    item={item}
                    itemId={item.id}
                    key={item.id}
                    setData={setData}
                    isAdmin={userData.roles.includes('admin')}
                  >
                    <ChartRenderer
                      vizState={{ ...item.vizState, id: item.id }}
                      id={item.id}
                      identifier={item.identifier}
                    />
                  </DashboardItem>
                </StyledCol>
              ))}
            </Row>
          </SectionWrapper>
        ) : null}
        {benchmarkingSegmentItems?.length ? (
          <SectionWrapper>
            <CardHeader title="Benchmarking" />
            <Row gutter={16}>
              {benchmarkingSegmentItems.map((item, index) => (
                <StyledCol key={item.id} span={calculateSpanBasedOnLength(benchmarkingSegmentItems, index)}>
                  <DashboardItem
                    data={data}
                    item={item}
                    itemId={item.id}
                    key={item.id}
                    setData={setData}
                    isAdmin={userData.roles.includes('admin')}
                  >
                    <ChartRenderer
                      vizState={{ ...item.vizState, id: item.id }}
                      id={item.id}
                      identifier={item.identifier}
                    />
                  </DashboardItem>
                </StyledCol>
              ))}
            </Row>
          </SectionWrapper>
        ) : null}
        {criteriaSegmentItems?.length ? (
          <SectionWrapper>
            <CardHeader title="Criteria" />
            <Row gutter={16}>
              {criteriaSegmentItems.map((item, index) => (
                <StyledCol key={item.id} span={calculateSpanBasedOnLength(criteriaSegmentItems, index)}>
                  <DashboardItem
                    data={data}
                    item={item}
                    itemId={item.id}
                    key={item.id}
                    setData={setData}
                    isAdmin={userData.roles.includes('admin')}
                  >
                    <ChartRenderer
                      vizState={{ ...item.vizState, id: item.id }}
                      id={item.id}
                      identifier={item.identifier}
                    />
                  </DashboardItem>
                </StyledCol>
              ))}
            </Row>
          </SectionWrapper>
        ) : null}
        {affordabilitySegmentItems?.length ? (
          <SectionWrapper>
            <CardHeader title="Affordability" />
            <Row gutter={16}>
              {affordabilitySegmentItems.map((item, index) => (
                <StyledCol key={item.id} span={calculateSpanBasedOnLength(affordabilitySegmentItems, index)}>
                  <DashboardItem
                    data={data}
                    item={item}
                    itemId={item.id}
                    key={item.id}
                    setData={setData}
                    isAdmin={userData.roles.includes('admin')}
                  >
                    <ChartRenderer
                      vizState={{ ...item.vizState, id: item.id }}
                      id={item.id}
                      identifier={item.identifier}
                    />
                  </DashboardItem>
                </StyledCol>
              ))}
            </Row>
          </SectionWrapper>
        ) : null}
        {userData.roles.includes('admin') && sourcingSegmentItems?.length ? (
          <SectionWrapper>
            <CardHeader title="Sourcing" />
            <Row gutter={16}>
              {sourcingSegmentItems.map((item, index) => (
                <StyledCol key={item.id} span={calculateSpanBasedOnLength(sourcingSegmentItems, index)}>
                  <DashboardItem
                    data={data}
                    item={item}
                    itemId={item.id}
                    key={item.id}
                    setData={setData}
                    isAdmin={userData.roles.includes('admin')}
                  >
                    <ChartRenderer
                      vizState={{ ...item.vizState, id: item.id }}
                      id={item.id}
                      identifier={item.identifier}
                    />
                  </DashboardItem>
                </StyledCol>
              ))}
            </Row>
          </SectionWrapper>
        ) : null}
        {itemsWithoutSegment?.length ? (
          <SectionWrapper>
            <Row gutter={16}>
              {itemsWithoutSegment.map((item, index) => (
                <StyledCol key={item.id} span={calculateSpanBasedOnLength(itemsWithoutSegment, index)}>
                  <DashboardItem
                    data={data}
                    item={item}
                    itemId={item.id}
                    key={item.id}
                    setData={setData}
                    isAdmin={userData.roles.includes('admin')}
                  >
                    <ChartRenderer
                      vizState={{ ...item.vizState, id: item.id }}
                      id={item.id}
                      identifier={item.identifier}
                    />
                  </DashboardItem>
                </StyledCol>
              ))}
            </Row>
          </SectionWrapper>
        ) : null}
        {userData.roles.includes('admin') && otherItems?.length ? (
          <SectionWrapper>
            <CardHeader title="Other" />
            <Row gutter={16}>
              {otherItems.map((item, index) => (
                <StyledCol key={item.id} span={calculateSpanBasedOnLength(otherItems, index)}>
                  <DashboardItem
                    data={data}
                    item={item}
                    itemId={item.id}
                    key={item.id}
                    setData={setData}
                    isAdmin={userData.roles.includes('admin')}
                  >
                    <ChartRenderer
                      vizState={{ ...item.vizState, id: item.id }}
                      id={item.id}
                      identifier={item.identifier}
                    />
                  </DashboardItem>
                </StyledCol>
              ))}
            </Row>
          </SectionWrapper>
        ) : null}
      </div>
    )
  }

  return (
    <div>
      <DashboardLayout>
        {renderDashboardItems()}
      </DashboardLayout>
    </div>
  )
}

export default connector(CommonPage)
