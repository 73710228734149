import {
  ELIXIR_BACKEND_INTEGRATION_HOSTNAME,
  ELIXIR_BACKEND_STAGING_HOSTNAME,
  ELIXIR_BACKEND_PRODUCTION_HOSTNAME,
  MBT_INTEGRATION_HOSTNAME,
  MBT_STAGING_HOSTNAME,
  MBT_PRODUCTION_HOSTNAME,
  ANALYTICS_INTEGRATION_HOSTNAME,
  ANALYTICS_STAGING_HOSTNAME,
  ANALYTICS_PRODUCTION_HOSTNAME,
  GATEWAY_INTEGRATION_HOSTNAME,
  GATEWAY_STAGING_HOSTNAME,
  GATEWAY_PRODUCTION_HOSTNAME,
  CUBESERVER_GATEWAY_PATH,
  LOCAL_DEVELOPMET_HOSTNAME,
} from '../constants'

export const getMbtMainUrl = () => {
  switch (window.location.host) {
  case ANALYTICS_INTEGRATION_HOSTNAME:
    return `https://${MBT_INTEGRATION_HOSTNAME}`

  case ANALYTICS_STAGING_HOSTNAME:
    return `https://${MBT_STAGING_HOSTNAME}`

  case ANALYTICS_PRODUCTION_HOSTNAME:
    return `https://${MBT_PRODUCTION_HOSTNAME}`

  default:
    return `https://${MBT_PRODUCTION_HOSTNAME}`
  }
}

export const getCubeServerUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return LOCAL_DEVELOPMET_HOSTNAME
  }

  switch (window.location.host) {
  case ANALYTICS_INTEGRATION_HOSTNAME:
    return `https://${GATEWAY_INTEGRATION_HOSTNAME}${CUBESERVER_GATEWAY_PATH}`

  case ANALYTICS_STAGING_HOSTNAME:
    return `https://${GATEWAY_STAGING_HOSTNAME}${CUBESERVER_GATEWAY_PATH}`

  case ANALYTICS_PRODUCTION_HOSTNAME:
    return `https://${GATEWAY_PRODUCTION_HOSTNAME}${CUBESERVER_GATEWAY_PATH}`

  default:
    return `https://${GATEWAY_PRODUCTION_HOSTNAME}${CUBESERVER_GATEWAY_PATH}`
  }
}

export const getElixirBackendUrl = () => {
  switch (window.location.host) {
  case ANALYTICS_INTEGRATION_HOSTNAME:
    return `https://${ELIXIR_BACKEND_INTEGRATION_HOSTNAME}`

  case ANALYTICS_STAGING_HOSTNAME:
    return `https://${ELIXIR_BACKEND_STAGING_HOSTNAME}`

  case ANALYTICS_PRODUCTION_HOSTNAME:
    return `https://${ELIXIR_BACKEND_PRODUCTION_HOSTNAME}`

  default:
    return `https://${ELIXIR_BACKEND_PRODUCTION_HOSTNAME}`
  }
}
